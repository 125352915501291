import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useFormFieldStyles = makeStyles()((theme: Theme) => ({
  formField: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "24px",
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    width: "100%",
  },
  formLabel: {
    fontWeight: "bold",
    marginLeft: "8px",
    marginBottom: "8px",
    fontSize: "0.9375rem",
    color: theme.mode.text.formLabel,
    fontFamily: "inherit",
    minHeight: "18px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.93rem",
    },
  },
  inputAdornEnd: {
    "& svg": {
      color: theme.mode.text.formLabel,
      fill: theme.mode.text.formLabel,
      marginRight: 2,
    },
  },
  dateTimeField: {
    background: theme.mode.background.input,
    width: "100%",
    borderRadius: "1rem",
    minHeight: "3.065rem",
    [theme.breakpoints.down("md")]: {
      minHeight: "2.93rem",
    },
    "& fieldset": {
      border: "none",
    },
    "& input": {
      fontSize: "0.8125rem",
      color: theme.mode.text.heading,
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "0.93rem",
      },
      "&::placeholder": {
        color: "#a9a9b2",
        fontWeight: "600",
        fontSize: "1.065rem",
        [theme.breakpoints.down("md")]: {
          fontSize: "0.93rem",
        },
      },
      "&:focus": {
        outline: "none",
      },
      "&:disabled": {
        opacity: 0.4,
      },
    },
  },
  formHint: {
    fontSize: "0.8rem",
    color: theme.palette.grey["400"],
    fontWeight: "revert",
  },
  formLabelRequired: {
    "&::after": {
      content: '"*"',
      marginLeft: "4px",
    },
  },
  formInput: {
    fontSize: "0.8125rem",
    color: theme.mode.text.heading,
    fontWeight: 600,
    border: "none",
    background: theme.mode.background.input,
    padding: "1rem",
    minHeight: "3.065rem",
    borderRadius: "1rem",
    fontFamily: "inherit",
    resize: "none",
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.93rem",
      minHeight: "2.93rem",
    },
    "&::placeholder": {
      color: "#a9a9b2",
      fontWeight: "600",
      fontSize: "0.8125rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.93rem",
      },
    },
    "&:focus": {
      outline: "none",
    },
    "&:disabled": {
      opacity: 0.4,
    },
  },
  inputErrorState: {
    outline: "1px solid #ff3333",
    "&:focus": {
      outline: "1px solid #ff3333",
    },
  },
  passwordIcon: {
    position: "absolute",
    right: "-6px",
    top: "44px",
    color: theme.mode.text.formLabel,
    cursor: "pointer",
  },
  formSelect: {
    appearance: "none",
    WebkitAppearance: "none",
    color: theme.mode.icon.default,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "95% center",
  },
  formError: {
    marginLeft: "8px",
    marginTop: "8px",
    color: theme.palette.error.main,
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
  },
  formActionsButton: {
    fontFamily: theme.typography.fontFamily,
    border: "none",
    padding: "16px 32px",
    textTransform: "uppercase",
    borderRadius: "32px",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 800,
    fontSize: "1.1rem",
    cursor: "pointer",
    "&[disabled]": {
      opacity: "0.4",
    },
  },
  formActionsButtonSecondary: {
    background: "none",
    color: theme.palette.grey["500"],
  },
  privateCard: {
    marginTop: theme.spacing(4),
  },
  dateTimeFieldContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "16px",
  },
  activityRelationship: {
    marginBottom: theme.spacing(3),
    justifyContent: "flex-start",
  },
  inputBase: {
    padding: 0,
  },
  endAdornment: {
    paddingRight: "8px",
    fill: theme.mode.text.formLabel,
  },
  visibleOverflow: {
    overflow: "initial",
  },
  muiSelect: {
    "& *": {
      color: theme.mode.text.description,
    },
    "& .MuiSelect-select": {
      color: theme.mode.text.description,
      fontWeight: "600",
    },
  },
  menuItemGutters: {
    paddingLeft: "0.875rem",
    paddingRight: "0.875rem",
  },
  menuItemRoot: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "&:first-of-type": {
      borderRadius: "1rem 1rem 0 0",
    },
    "&:last-of-type": {
      borderRadius: "0 0 1rem 1rem",
    },
  },
  timePickerPaper: {
    "& span": {
      color: theme.mode.text.formLabel,
    },
    "& div[role='listbox']": {
      outline: "none",
    },
  },
  datePickerPaper: {
    "& span.MuiTypography-caption": {
      color: theme.mode.text.formLabel,
    },
    "& .MuiPickersDay-root": {
      color: theme.mode.text.formLabel,
      backgroundColor: theme.mode.background.default,
    },
    "& .MuiPickersDay-root.Mui-selected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiPickersDay-root:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    "& .MuiPickersDay-root:disabled": {
      backgroundColor: theme.mode.background.calendarCell,
    },
    "& span": {
      color: theme.mode.text.formLabel,
    },
    "& div[role='listbox']": {
      outline: "none",
    },
  },
  emptyOption: {
    color: theme.mode.text.disabled,
  },
  dateInputWrapper: {
    display: "flex",
    alignItems: "center",
  },
  articleEditor: {
    marginBottom: theme.spacing(5),
  },
  contentLengthHint: {},
  popupField: {
    marginTop: "-8px",
  },
}));
