import React from "react";

function EyeIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43.167"
      height="40"
      viewBox="0 0 43.167 40"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M3.808 3.359a.76.76 0 0 1 .727.192l1.282 1.266 1.102 1.088 7.137 7.056 1.5 1.48.974.955a.736.736 0 0 1 0 1.052.754.754 0 0 1-1.065 0l-2.024-2.007-.442-.436-1.005-.992-.052-.052-5.48-5.405-1.313-1.258L3.47 4.602a.737.737 0 0 1-.195-.718.748.748 0 0 1 .533-.525zm.944 3.583 1.17 1.125 1.499 1.48c-.026.15-.041.3-.045.452 0 .694.282 1.359.782 1.846a2.642 2.642 0 0 0 2.337.701l.952.94.052.052.968.992.157.156a7.185 7.185 0 0 1-2.422.496h-.187c-4.416 0-6.942-4.005-7.415-4.812a.732.732 0 0 1 0-.74c.581-.995 1.306-1.9 2.152-2.688zm5.046-2.125c4.483-.133 7.122 3.99 7.602 4.812a.732.732 0 0 1 0 .74 11.791 11.791 0 0 1-2.182 2.688l-.63-.622-2.009-1.984c.026-.15.041-.3.045-.452 0-1.43-1.175-2.59-2.624-2.59a3.089 3.089 0 0 0-.457.044l-2.1-2.073-.067-.067a7.185 7.185 0 0 1 2.422-.496z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default EyeIcon;
