import React, { HTMLProps, ReactNode } from "react";

import { useFormFieldStyles } from "./FormField.styles";

type FormFieldProps = {
  id?: string;
  label?: string;
  hint?: string | null;
  required?: boolean;
  children: ReactNode;
} & HTMLProps<HTMLDivElement>;

export const FormField = ({ id, label, hint, required, children, ...props }: FormFieldProps) => {
  const { classes } = useFormFieldStyles();

  return (
    <div className={classes.formField} {...props}>
      <label className={classes.formLabel} htmlFor={id}>
        {label && (
          <span>
            {label}
            {required && <i aria-hidden className={classes.formLabelRequired}></i>}
          </span>
        )}
        {hint && <span className={classes.formHint}>{hint}</span>}
      </label>
      {children}
    </div>
  );
};

export default FormField;
