import React from "react";

function EyeOpenIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43.167"
      height="40"
      viewBox="0 0 43.167 40"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M9.798 5.003c4.483-.139 7.122 4.17 7.602 5.029a.795.795 0 0 1 0 .773c-.645 1.16-3.052 4.92-7.198 5.028h-.187c-4.416 0-6.942-4.185-7.415-5.028a.795.795 0 0 1 0-.773c.653-1.16 3.052-4.92 7.198-5.029zM10 7.711c-1.45 0-2.624 1.212-2.624 2.707 0 1.496 1.175 2.708 2.624 2.708 1.45 0 2.624-1.212 2.624-2.708 0-1.495-1.175-2.707-2.624-2.707z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default EyeOpenIcon;
