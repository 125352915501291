import React, { forwardRef, InputHTMLAttributes, useState } from "react";

import ErrorMessage from "@/common/components/ErrorMessage";
import FormField from "@/common/components/FormField";
import { useFormFieldStyles } from "@/common/components/FormField.styles";

import EyeIcon from "@/common/icons/EyeIcon";
import EyeOpenIcon from "@/common/icons/EyeOpenIcon";

interface PasswordFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  errorMessage?: string;
  name: string;
  datacy?: string;
  isIconReversed?: boolean;
  isInPopup?: boolean;
}

const Wrapper = (
  {
    id,
    label,
    required,
    placeholder,
    errorMessage,
    name,
    datacy,
    isIconReversed,
    isInPopup,
    ...props
  }: PasswordFieldProps,
  ref: any,
) => {
  const { classes } = useFormFieldStyles();
  const [inputType, setInputType] = useState("password");

  const onChangeType = () => {
    inputType == "password" ? setInputType("text") : setInputType("password");
  };

  const icon = () => {
    return inputType == "password" ? (
      <EyeIcon className={classes.passwordIcon} onClick={onChangeType} />
    ) : (
      <EyeOpenIcon className={classes.passwordIcon} onClick={onChangeType} />
    );
  };

  const iconReversed = () => {
    return inputType == "password" ? (
      <EyeOpenIcon className={classes.passwordIcon} onClick={onChangeType} />
    ) : (
      <EyeIcon className={classes.passwordIcon} onClick={onChangeType} />
    );
  };

  return (
    <FormField id={id} label={label} required={required}>
      <input
        ref={ref}
        {...props}
        className={`${classes.formInput} ${errorMessage ? classes.inputErrorState : ""}${
          isInPopup ? classes.popupField : ""
        } `}
        id={id}
        type={inputType}
        name={name}
        data-cy={datacy ? datacy : name}
        placeholder={placeholder}
        required={required}
      />
      {isIconReversed ? iconReversed() : icon()}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} name={name} />}
    </FormField>
  );
};

export const PasswordField = forwardRef(Wrapper);
